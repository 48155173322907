import "./import/modules";
import LocomotiveScroll from 'locomotive-scroll';

if (document.querySelector('[data-scroll-container]')) {
    let locomotive = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        tablet: {
            breakpoint: 1200
        },
    })
    window.addEventListener("load", (function () {
        if (document.querySelector('[data-scroll-container]')) {
            locomotive.update()
        }
    }))
    window.addEventListener("resize", (function () {
        if (document.querySelector('[data-scroll-container]')) {
            locomotive.update()
        }
    }))
}