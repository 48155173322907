let menuLinks = document.querySelectorAll('.nav-menu__link'),
    path = document.location.pathname

Array.prototype.slice.call(menuLinks).forEach(function (menuLink) {
    if (path === menuLink.getAttribute('href')) {
        menuLink.classList.add('is-active');
    } else if (path.includes(menuLink.getAttribute('href'))) {
        menuLink.classList.add('is-active');
    }
})

document.getElementById('menu').addEventListener('click', function (e) {
    this.classList.toggle('menu-toggle')
    document.body.classList.toggle('modal-open')
    document.querySelector('.menu-wrapper').classList.toggle('menu-wrapper_show')
})